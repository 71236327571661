<template>
  <div class="workbench-container">
    <el-row v-show="loading == false" :gutter="20">
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">小程序数据概览</span>
              <span class="desc">数据更新于 {{ nowTime }}</span>
            </div>
          </template>
          <target
            :user-conversion="userConversion"
            :user-pv-data="userPvData"
          />
        </el-card>
      </el-col>

      <!-- <el-col :lg="8" :md="12" :sm="24" :xl="6" :xs="24">
        <order />
      </el-col> -->

      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">小程序数据概览</span>
              <span class="desc">数据更新于 昨日</span>
            </div>
          </template>
          <user-status
            :brisk-user-data="briskUserData"
            :new-user-data="newUserData"
          />
        </el-card>
      </el-col>

      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">展会数据概览</span>
              <span class="desc">数据更新于 {{ nowTime }}</span>
            </div>
          </template>
          <exhibition-data
            :exhibition-data="exhibitionData"
            :meeting-data="meetingData"
          />
        </el-card>
      </el-col>

      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">主办方数据概览</span>
              <span class="desc">数据更新于 {{ nowTime }}</span>
            </div>
          </template>
          <host-data :host-data="hostData" />
        </el-card>
      </el-col>

      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">互动数据概览</span>
              <span class="desc">数据更新于 {{ nowTime }}</span>
            </div>
          </template>
          <interaction-data :interaction-data="interactionData" />
        </el-card>
      </el-col>

      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">展览数据分析</span>
              <span class="desc">数据更新于 {{ nowTime }}</span>
            </div>
          </template>
          <single-exhibition-data />
        </el-card>
      </el-col>

      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">会议数据分析</span>
              <span class="desc">数据更新于 {{ nowTime }}</span>
            </div>
          </template>
          <single-meeting-data />
        </el-card>
      </el-col>
    </el-row>

    <Loading v-show="loading == true" :rows="20" />
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, toRefs } from 'vue'
  import Order from './components/Order'
  import Target from './components/Target'
  import UserStatus from './components/userStatus'
  import ExhibitionData from './components/exhibitionData'
  import HostData from './components/hostData'
  import InteractionData from './components/interactionData'
  import SingleExhibitionData from './components/singleExhibitionData'
  import SingleMeetingData from './components/singleMeetingData'
  import Loading from '@/components/loading/loading'
  import { getMiniData, getPlatformData } from '@/api/workBench'
  import { parseTime } from '@/utils/index'
  export default defineComponent({
    name: 'Workbench',
    components: {
      Order,
      UserStatus,
      Target,
      ExhibitionData,
      HostData,
      InteractionData,
      SingleExhibitionData,
      SingleMeetingData,
      Loading,
    },
    setup() {
      const state = reactive({
        loading: true,
        confirm: {
          one: false,
          two: false,
          three: false,
          four: false,
          five: false,
        },
        //  新增用户概况
        newUserData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '日新增用户',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '周新增用户',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '月新增用户',
            colCount: 8,
          },
        ],
        // 小程序活跃用户概览
        briskUserData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '实名制访问人数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '日访问人数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '日打开次数',
            colCount: 8,
          },
        ],
        // 小程序用户转化数据
        userConversion: [],
        // 实时访问数据
        userPvData: {},

        // 主办方数据概览
        hostData: [],

        // 互动数据概览
        interactionData: [],

        // 展会办个展数据概览
        exhibitionData: [],

        // 展会办个会数据概览
        meetingData: [],
        nowTime: '',
      })

      onMounted(() => {
        state.nowTime = parseTime(new Date().getTime())
        fetchMiniData()
      })

      const fetchMiniData = async () => {
        const { data } = await getMiniData()
        console.log(data)
        state.newUserData[0].countConfig.endVal = data.yesterdayVisitUvNew
        state.newUserData[1].countConfig.endVal = data.weeklyVisitUvNew
        state.newUserData[2].countConfig.endVal = data.monthlyVisitUvNew

        state.briskUserData[0].countConfig.endVal = data.yesterdayRealVisit
        state.briskUserData[1].countConfig.endVal = data.yesterdayVisitUv
        state.briskUserData[2].countConfig.endVal = data.yesterdaySessionCnt

        state.userConversion = [data.realTotal, data.mp2, data.mp1]
        state.userPvData.sessionCnts = data.sessionCnts
        state.userPvData.toReals = data.toReals
        state.userPvData.visitUvNews = data.visitUvNews

        const { data: plateData } = await getPlatformData({
          exhibitionCode: '',
          meetingCode: '',
        })
        console.log(plateData)
        state.hostData = plateData.hostReportVo
        state.interactionData = plateData.interactionReportVo
        state.exhibitionData = plateData.exhibitionReportVo
        state.meetingData = plateData.meetingReportVo

        setTimeout(() => {
          state.loading = false
        }, 800)
      }

      return {
        ...toRefs(state),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .workbench-container {
    padding: 0 !important;
    background: $base-color-background !important;
  }
  .card-header {
    .title {
      font-size: 14px;
      color: #333;
    }
    .desc {
      font-size: 12px;
      color: #999;
    }
  }
</style>
